import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LANGUAGES } from 'src/app/models/languages';
import { BookingService } from 'src/app/services/booking.service';

@Component({
  selector: 'app-title-with-languages',
  template: `<div class="d-flex flex-row title-with-languages mb-3">
    <h3 class="inner-title w-100">
      {{ title | translate }}
    </h3>
    <div ngbDropdown class="d-flex flags-button d-block d-sm-none">
      <button type="button" class="btn btn-outline-light" ngbDropdownToggle>
        <img [src]="'assets/flags/' + selectedLang.value.file" height="20" width="30" class="object-fit-cover border" />
      </button>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-end">
        <button type="button" ngbDropdownItem *ngFor="let l of languages" (click)="selectedLang.setValue(l)">
          <img [src]="'assets/flags/' + l.file" height="20" width="30" class="me-1 object-fit-cover border" />
          {{ l.name }}
        </button>
      </div>
    </div>
  </div>`,
})
export class TitleWithLanguagesComponent {
  @Input() title: string = '';
  languages = LANGUAGES;
  selectedLang: FormControl = new FormControl();

  constructor(private bookingService: BookingService) {
    this.selectedLang.setValue(this.languages.find((y) => y.id == this.bookingService.lang));
    this.selectedLang.valueChanges.subscribe((x) => {
      this.bookingService.lang = this.selectedLang.value.id;
      localStorage.setItem('lang', this.selectedLang.value.id);
    });
  }
}
