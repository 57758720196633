import { Component, ElementRef, TemplateRef, ViewChild, AfterViewInit, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateAdapter, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter, forkJoin, map, of, switchMap, catchError, combineLatest } from 'rxjs';
import {
  MandatoryFieldsLMApi,
  CodeNameOrderIconLMApi,
  ActionTypeLMApi,
  ConfigurationTypesCodeNameOrderIconLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  BookingShipmentLMApi,
  CustomerPlantModelExtLMApi,
  ProductBookingLMApi,
  BookingLMApiService,
  ShipmentLMApiService,
  DeleteFileRequestLMApi,
  UploadFileResponseLMApi,
  GetAuthTokenFromBookingCodeResponseLMApi,
  GetInfoInputLMApi,
  ShipmentInfoLMApi,
  CanEditBookingRequestLMApi,
  CanEditBookingResponseLMApi,
  DirectionLMApi,
  GetSlotsRequestLMApi,
  GetSlotsResponseLMApi,
  AdvanceStatusInputLMApi,
  OptionalsLMApi,
  ShipmentStatusLMApi,
} from 'src/app/api';
import { BookingService } from 'src/app/services/booking.service';
import { NgbDateUtcAdapter } from 'src/app/services/ngb-date-adapter';
import { getDateInTimezone, addHours, mobileAndTabletCheck } from 'src/app/services/utils';
import { AddProductDialogComponent } from '../../dialogs/add-product-dialog/add-product-dialog.component';
import { EditProductDialogComponent } from '../../dialogs/edit-product-dialog/edit-product-dialog.component';
import { AttachmentFileData } from 'src/app/models/interfaces';
import * as intlTelInput from 'intl-tel-input';
import * as _ from 'lodash';
import { DeleteBookingDialogComponent } from 'src/app/dialogs/delete-booking-dialog/delete-booking-dialog.component';
import { environment } from 'src/environments/environment';
import { ErrorMessageDialogComponent } from 'src/app/dialogs/error-message-dialog/error-message-dialog.component';
import { NavigationService } from 'src/app/services/navigation.service';

declare function downloadFileCSharp(url: string, token: string, fileName: string): any;

@Component({
  selector: 'app-edit-shipment',
  templateUrl: './edit.component.html',
  providers: [{ provide: NgbDateAdapter, useClass: NgbDateUtcAdapter }],
})
export class EditComponent implements AfterViewInit {
  loaded: boolean = false;
  bookingCodeCripted: string | undefined;
  configuration: CustomerPlantConfigurationModelExtLMApi | null = null;
  mandatoryFieldsActive: MandatoryFieldsLMApi | undefined = undefined;
  typeActive: ConfigurationTypesCodeNameOrderIconLMApi | undefined = undefined;
  model: BookingShipmentLMApi = {};
  selectedWarehouseData!: CustomerPlantModelExtLMApi | undefined;
  minDateStruct!: NgbDateStruct;
  date: Date | undefined;
  minDate: Date | undefined;
  hour: number | undefined;
  hoursList: number[] | undefined;
  file: any;
  warehouseCode: string | null | undefined;
  attachmentRandomGuidForTemporary: string | null | undefined;
  attachmentsFileList: AttachmentFileData[][] | null | undefined;
  attachmentsFileErrors: string[][] = [[], [], []];
  loadingAttachments: boolean[] = [false, false, false];
  now: Date | null = new Date();
  intl: intlTelInput.Plugin | undefined = undefined;
  intlValid: boolean | undefined;
  emptyPhoneNumber: boolean = true;
  loaded$ = new EventEmitter<boolean>();
  loadedSlot: boolean = true;
  @ViewChild('driverPhoneInput') driverPhoneInput!: ElementRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public bookingService: BookingService,
    private shipmentApiService: ShipmentLMApiService,
    private bookingApiService: BookingLMApiService,
    private modal: NgbModal
  ) {
    // RECUPERO I PARAMETRI DALLA ROUTE E OTTENGO IL TOKEN
    this.route.paramMap.subscribe((paramMap) => {
      /* let lang = paramMap.get("lang");
      //GESTIONE LINGUA
      var localStorageLang = localStorage.getItem("lang");
      if (localStorageLang) this.bookingService.lang = localStorageLang;
      else if (lang) this.bookingService.lang = lang; */

      //GESTIONE STEPPER
      let url = router.url.substring(1);
      this.bookingService.setStepper([
        { label: '1', url: url, desktopPhase: 0, disabled: true },
        { label: '2', url: url, desktopPhase: 0 },
        { label: '3', url: url, desktopPhase: 1 },
        { label: '4', url: url + '/edit-slot' },
        { label: 'END', url: url + '/edit-slot/add-reason' },
      ]);
      bookingService.desktopStep === 0 ? this.bookingService.setStepperActive(1) : this.bookingService.setStepperActive(2);

      let bookingCodeCripted = paramMap.get('bookingCodeCripted');
      if (bookingCodeCripted) {
        this.bookingCodeCripted = bookingCodeCripted;
        this.shipmentApiService
          .getAuthTokenFromBookingCode(bookingCodeCripted)
          .pipe(
            switchMap((token: GetAuthTokenFromBookingCodeResponseLMApi | null | undefined) =>
              this.bookingApiService
                .canEditBooking(token!.authToken!, { bookingCodeCripted: this.bookingCodeCripted } as CanEditBookingRequestLMApi)
                .pipe(map((canEditResponse: CanEditBookingResponseLMApi) => ({ token, canEditResponse })))
            ),
            catchError((err, r) => {
              return of({ token: null, canEditResponse: null });
            })
          )
          .subscribe(({ token, canEditResponse }: { token: GetAuthTokenFromBookingCodeResponseLMApi | null | undefined; canEditResponse: CanEditBookingResponseLMApi | null }) => {
            if (!token || !canEditResponse || (canEditResponse && canEditResponse.notFound)) {
              this.router.navigate(['/404-shipment']);
            } else {
              this.bookingService.canEditResponse = canEditResponse;
              if (!canEditResponse.deleted && !canEditResponse.rejected) {
                this.bookingService.token = token;
              } else {
                this.loaded = true;
                this.loaded$.emit();
              }
            }
          });
      }
    });
    // CON IL TOKEN OTTENGO I DATI DELLA SHIPMENT E WAREHOUSE
    this.bookingService.$token
      .pipe(
        filter((token) => token != null && token != undefined),
        switchMap((token: GetAuthTokenFromBookingCodeResponseLMApi | null | undefined) =>
          forkJoin([
            this.shipmentApiService.getInfo(token!.key!, { bookingCode: this.bookingCodeCripted } as GetInfoInputLMApi),
            this.bookingApiService.getWarehouseConfiguration(token!.authToken!),
            this.bookingApiService.getWarehouseAddress(token!.authToken!),
          ])
        ),
        catchError((err, r) => {
          return of();
        })
      )
      .subscribe(([shipmentInfo, warehouseConfiguration, warehouseData]: [ShipmentInfoLMApi, CustomerPlantConfigurationModelExtLMApi, CustomerPlantModelExtLMApi]) => {
        //SETTO PARAMETRI DEL SERVICE
        this.bookingService.selectedWarehouseData = warehouseData;
        this.bookingService.warehouseConfiguration = warehouseConfiguration;
        this.bookingService.data = this.bookingService.data.code
          ? this.bookingService.data
          : {
              truckType: shipmentInfo?.truckType?.code,
              tripType: null,
              products: shipmentInfo?.materials?.map(
                (x) =>
                  ({
                    id: x.id,
                    code: warehouseConfiguration.product?.types?.find((y) => y.name == x.handlingUnit)?.code,
                    quantity: x.quantity,
                    sourceDestination: warehouseConfiguration.sourceDestination?.types?.find((y) => y.name == x.sourceDestination)?.code,
                    deliveryMethod: warehouseConfiguration.deliveryMethod?.types?.find((y) => y.name == x.shippingMode)?.code,
                    action: x.activityType,
                  } as ProductBookingLMApi)
              ),
              code: shipmentInfo?.bookingCode,
              gateCode: shipmentInfo?.gateCode,
              email: shipmentInfo?.email,
              phoneNumber: shipmentInfo?.optionals?.driverPhone,
              carrier: shipmentInfo?.optionals?.carrierCode,
              sender: shipmentInfo?.optionals?.sender,
              receiver: shipmentInfo?.optionals?.receiver,
              referenceName: shipmentInfo?.optionals?.referenceName,
              referenceNumber: shipmentInfo?.optionals?.referenceCode,
              secReferenceNumber: shipmentInfo?.optionals?.external,
              truckPlate: shipmentInfo?.optionals?.truckPlate,
              trailerPlate: shipmentInfo?.optionals?.trailerPlate,
              notes: shipmentInfo?.optionals?.notes,
              startDT: shipmentInfo?.bookingDate,
            };
        this.bookingService.attachmentsFileList = [
          shipmentInfo?.attachments1
            ? shipmentInfo?.attachments1?.map((x) => ({ fileName: x.originalName, filePath: x.path, validFileName: x.name } as AttachmentFileData))
            : ([] as AttachmentFileData[]),
          shipmentInfo?.attachments2
            ? shipmentInfo?.attachments2?.map((x) => ({ fileName: x.originalName, filePath: x.path, validFileName: x.name } as AttachmentFileData))
            : ([] as AttachmentFileData[]),
          shipmentInfo?.attachments3
            ? shipmentInfo?.attachments3?.map((x) => ({ fileName: x.originalName, filePath: x.path, validFileName: x.name } as AttachmentFileData))
            : ([] as AttachmentFileData[]),
        ];
        this.bookingService.editInitialBookingDate = shipmentInfo.bookingDate;
        this.bookingService.selectedDate =
          this.bookingService.selectedDate ??
          getDateInTimezone(
            new Date(shipmentInfo?.bookingDate!),
            this.bookingService.warehouseConfiguration?.timezone ? this.bookingService.warehouseConfiguration.timezone : 'UTC'
          );
        this.bookingService.selectedHour = this.bookingService.selectedDate.getHours();
        this.warehouseCode = shipmentInfo.warehouseCode;
        //SETTO PARAMETRI DEL COMPONENTE
        this.model = this.bookingService.data;
        this.attachmentRandomGuidForTemporary = this.bookingService.attachmentRandomGuidForTemporary;
        this.attachmentsFileList = this.bookingService.attachmentsFileList;
        this.configuration = this.bookingService.warehouseConfiguration;
        this.selectedWarehouseData = this.bookingService.selectedWarehouseData;
        let d = new Date();
        let warehouseNowDate = getDateInTimezone(new Date(d.getFullYear(), d.getMonth(), d.getDate(), d.getHours()), this.configuration.timezone ?? 'UTC');
        this.bookingService.minValidDate =
          this.configuration.freezeEditBooking && this.configuration.freezeHours && this.configuration.freezeHours > 0
            ? addHours(warehouseNowDate, this.configuration.freezeHours ?? 0)
            : warehouseNowDate;

        this.date = this.bookingService.selectedDate;
        this.hour = this.bookingService.selectedHour;
        /* this.bookingService.canEdit && this.changeDate(); */
        this.changeConfiguration();
        this.loaded = true;
        this.loaded$.emit();

        // Setto il numero di telefono se presente (Dovrebbe sempre avvenire dopo ngAfterViewInit() )
        this.model?.phoneNumber && this.intl?.setNumber(this.model?.phoneNumber);
        this.handleChangePhoneNumber();
      });
  }

  ngAfterViewInit() {
    let driverPhoneInput: HTMLElement = this.driverPhoneInput.nativeElement;
    this.intl = intlTelInput(driverPhoneInput!, {
      utilsScript: 'assets/intl/utils.js',
      initialCountry: 'it',
      separateDialCode: true,
      preferredCountries: ['it', 'gb', 'es', 'fr', 'de', 'pl', 'ro', 'ru'], // TODO chiedere a pasquale quali
    });

    // Setto il numero di telefono se presente (Dovrebbe sempre avvenire dopo ngAfterViewInit() )
    this.model?.phoneNumber && this.intl?.setNumber(this.model?.phoneNumber);
    this.handleChangePhoneNumber();

    // listen to "keyup", but also "change" to update when the user selects a country
    driverPhoneInput.addEventListener('change', this.handleChangePhoneNumber);
    driverPhoneInput.addEventListener('keyup', this.handleChangePhoneNumber);
    driverPhoneInput.addEventListener('countrychange', this.handleChangePhoneNumber);
  }

  handleChangePhoneNumber = () => {
    if (this.intl) {
      let number = this.intl?.getNumber();
      //IL BACKSLASH DAVANTI AL + SERVE PER LA REGEXP
      let dialCodeString = '\\+' + this.intl?.getSelectedCountryData().dialCode;
      this.emptyPhoneNumber = number ? number.replace(new RegExp(dialCodeString, 'g'), '') === '' : true;
      if (number !== '') {
        this.intlValid = this.intl?.isValidNumber();
        if (this.intlValid) {
          this.model.nation = this.intl?.getSelectedCountryData().iso2;
          this.model.phoneNumber = number;
        } else {
          this.model.phoneNumber = undefined;
        }
      } else {
        this.intlValid = false;
        this.model.phoneNumber = undefined;
      }
    }
  };

  /*   changeDate = () => {
    if (this.date) {
      this.bookingApiService.getWarehouseHoursListByDate(this.bookingService.selectedWarehouseData?.token!, { date: this.date.toISOString() }).subscribe((res: number[]) => {
        let hours = res.map((x) => {
          let newD = new Date(Date.UTC(this.date!.getFullYear(), this.date!.getMonth(), this.date!.getDate(), x, 0, 0, 0));
          let newH = getDateInTimezone(newD, this.configuration?.timezone ? this.configuration?.timezone : "UTC");
          return newH;
        });

        if (this.date! <= this.bookingService.minValidDate!) {
          hours = hours?.filter((x) => x >= this.bookingService.minValidDate!);
        }
        this.hoursList = hours?.map((x) => x.getHours());
        this.hour = this.hour ? this.hoursList?.find((x) => x == this.hour) : undefined;
      });
    } else {
      this.hour = undefined;
      this.hoursList = [];
    }
  }; */

  addProduct = () => {
    if (!this.bookingService.isScreenSmall$.value) {
      this.changeConfiguration();
      const emptyProduct = {
        id: 0,
        action: undefined,
        code: null,
        quantity: undefined,
        deliveryMethod: null,
        sourceDestination: null,
      };
      this.model.products ? this.model.products.push(emptyProduct) : (this.model.products = [emptyProduct]);
    } else {
      let ref = this.modal.open(AddProductDialogComponent, { centered: true });
      ref.componentInstance.configuration = this.configuration;
      ref.componentInstance.typeActive = this.typeActive;
      ref.closed.subscribe((res: ProductBookingLMApi) => {
        if (res) {
          this.model.products ? this.model.products.push(res) : (this.model.products = [res]);
          this.changeConfiguration();
        }
      });
    }
  };
  editProduct = (data: ProductBookingLMApi, index: number) => {
    let ref = this.modal.open(EditProductDialogComponent, { centered: true });
    ref.componentInstance.configuration = this.configuration;
    ref.componentInstance.typeActive = this.typeActive;
    ref.componentInstance.data = data;
    ref.closed.subscribe((res: ProductBookingLMApi) => {
      if (res) {
        this.model?.products ? (this.model.products[index] = res) : {};
        this.changeConfiguration();
      }
    });
  };
  removeProduct = (content: TemplateRef<any>, index: number) => {
    of(this.bookingService.isScreenSmall$.value)
      .pipe(
        switchMap((isMobile) => {
          return isMobile ? this.modal.open(content, { centered: true }).closed : of(true);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.model.products ? this.model.products.splice(index, 1) : null;
          this.changeConfiguration();
          // Forzatura per resettare scelta tipo attività se rimane solo un item
          if (this.model.products?.length == 1)
            // TODO Filtro applicato per gestire tipi che sono sempre Load e Unload, vedere se possiamo migliorare
            this.typeActive!.types = this.bookingService.warehouseConfiguration.type?.types?.filter((t) =>
              this.bookingService.warehouseConfiguration.workflowsEnabled?.includes(<ActionTypeLMApi>t.code)
            );
        }
      });
  };

  /*
    ActionType
      None = 0,
      Load = 1,
      Unload = 2,
      LoadUnload = 3
  */
  changeConfiguration = () => {
    // MODIFICO LA CONFIGURAZIONE IN BASE ALLA SCELTA DEL TIPO LOAD O UNLOAD O ENTRAMBI
    let typesIncluded = this.model?.products?.filter((x) => x.action).map((x) => x.action);
    let fields: MandatoryFieldsLMApi | undefined;
    let types: CodeNameOrderIconLMApi[] | null | undefined;
    let configType = this.bookingService.warehouseConfiguration.type;
    let workflowsEnabled = this.configuration?.workflowsEnabled;
    let mandatoryFields = this.configuration?.mandatoryFields;

    if (typesIncluded && typesIncluded.length > 0) {
      if (!typesIncluded?.includes(ActionTypeLMApi.Unload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload) ? configType?.types : configType?.types?.filter((x) => x.code === ActionTypeLMApi.Load);
      } else if (!typesIncluded?.includes(ActionTypeLMApi.Load)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Unload);
        types = workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload) ? configType?.types : configType?.types?.filter((x) => x.code === ActionTypeLMApi.Unload);
      } else {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.LoadUnload);
        types = configType?.types;
      }
    } else {
      if (workflowsEnabled?.includes(ActionTypeLMApi.LoadUnload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.LoadUnload);
        types = configType?.types;
      } else if (!workflowsEnabled?.includes(ActionTypeLMApi.Unload)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = configType?.types?.filter((x) => x.code === ActionTypeLMApi.Load);
      } else if (!workflowsEnabled?.includes(ActionTypeLMApi.Load)) {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Unload);
        types = configType?.types?.filter((x) => x.code === ActionTypeLMApi.Unload);
      } else {
        fields = mandatoryFields?.find((x) => x.workflow == ActionTypeLMApi.Load);
        types = configType?.types;
      }
    }

    this.mandatoryFieldsActive = fields;
    this.typeActive = { ...configType, types: types };
  };

  uploadFile = (event: any, index: number) => {
    this.attachmentsFileErrors[index].length = 0;
    let files: FileList = event.target.files;
    if (files && files.length > 0) {
      this.loadingAttachments[index] = true;
      for (let i = 0; i < files.length; i++) {
        let file: File = files[i];
        this.bookingApiService.uploadBookingFile(this.bookingService.selectedWarehouseData?.token!, index + 1, this.model?.code!, file).subscribe((r: UploadFileResponseLMApi) => {
          if (r && !r.errorMessage) {
            if (this.attachmentsFileList && this.attachmentsFileList[index])
              this.attachmentsFileList[index].push({ fileName: r.originalFileName, filePath: '', validFileName: r.validFileName });
            else {
              this.attachmentsFileList = [[], [], []];
              this.attachmentsFileList[index] = [{ fileName: r.originalFileName, filePath: '', validFileName: r.validFileName }];
            }
          } else if (r.errorMessage) {
            this.attachmentsFileErrors[index].push(r.errorMessage);
          }
          this.loadingAttachments[index] = false;
        });
      }
    }
    event.target.value = '';
  };

  deleteFile = (fileData: AttachmentFileData, index: number) => {
    this.attachmentsFileErrors[index].length = 0;
    this.loadingAttachments[index] = true;
    this.bookingApiService
      .deleteBookingFile(this.bookingService.selectedWarehouseData?.token!, { bookingCode: this.model?.code!, validFileName: fileData.validFileName! } as DeleteFileRequestLMApi)
      .subscribe((r) => {
        if (r) {
          if (this.attachmentsFileList && this.attachmentsFileList.length > index)
            this.attachmentsFileList[index] = this.attachmentsFileList[index].filter((x) => x.validFileName !== fileData.validFileName);
          else this.attachmentsFileList = [[], [], []];
        } else {
          this.attachmentsFileErrors[index].push('Error Deleting File');
        }
        this.loadingAttachments[index] = false;
      });
  };

  downloadFile = (attachment: AttachmentFileData) => {
    let customerDatabaseId = this.model.code?.slice(0, -10) as string;
    if (mobileAndTabletCheck()) {
      downloadFileCSharp(
        `${environment.baseApiUrl}/api/Shipment/DownloadShipmentAttachment/${customerDatabaseId}/${this.warehouseCode!}/${this.model.code!}/${attachment.fileName!}`,
        this.bookingService.token!.key!,
        attachment.fileName!
      );
    } else {
      this.shipmentApiService
        .downloadShipmentAttachment(customerDatabaseId, this.warehouseCode!, this.model.code!, attachment.fileName!, this.bookingService.token!.key!)
        .subscribe((res) => {
          if (res) {
            var url = window.URL.createObjectURL(res);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = attachment.fileName!;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
          }
        });
    }
  };

  deleteShipment = () => {
    this.bookingService.toDelete = true;

    // let modalRef = this.modal.open(DeleteBookingDialogComponent, { centered: true });
    // modalRef.closed.subscribe((res) => {
    //   if (res) {
    let url = this.router.url.substring(1);
    url = url + '/edit-slot/add-reason';
    console.log(this.bookingService.toDelete);
    this.router.navigate([url]);
    //   }
    // });
  };

  checkProductData = () => {
    return !this.model.products ? true : this.model.products?.some((x) => !x.code || !x.action || !x.deliveryMethod || !x.quantity || x.quantity <= 0 || !x.sourceDestination);
  };

  goBack = () => {
    this.router.navigate(['view/' + this.bookingCodeCripted]);
  };
  goNextWithDesktopScreenMode = () => {
    this.bookingService.desktopStep = 1;
    this.bookingService.setStepperActive(2);
  };
  goBackWithDesktopScreenMode = () => {
    if (!this.bookingService.isScreenSmall$.value) {
      this.bookingService.desktopStep = 0;
      this.bookingService.setStepperActive(1);
    } else this.goBack();
  };
  goNext = () => {
    this.bookingService.canSelectNewSlot = false;
    if (
      this.bookingService.canEditResponse?.deleted ||
      this.bookingService.canEditResponse?.rejected ||
      this.bookingService.canEditResponse?.entrance ||
      this.bookingService.canEditResponse?.completed
    ) {
      // NON DEVE ACCADERE IN QUANTO IL PULSANTE E' NASCOSTO
      this.bookingService.checkSameSlotValid = true;
      this.bookingService.canSelectNewSlot = false;
      this.bookingService.getSlots = undefined;
      this.router.navigate(['edit-slot'], { relativeTo: this.route.parent });
    } else if (!this.bookingService.canEditResponse?.canEditMandatory) {
      this.bookingService.getSlots = undefined;
      this.router.navigate(['edit-slot'], { relativeTo: this.route.parent });
    } else {
      this.bookingService.canSelectNewSlot = true;

      let dateToFind = _.cloneDeep(this.date!);
      dateToFind.setHours(+this.hour!);

      let getSlotRequest: GetSlotsRequestLMApi = {
        dateUtc: dateToFind.toISOString(),
        truckType: this.model.truckType,
        minValidDate: this.bookingService.minValidDate?.toISOString(),
        products: this.model.products,
        direction: DirectionLMApi.Forward,
        filteredCode: this.model.code,
        useMaxSlotLimits: true,
      };
      this.loadedSlot = false;
      /**
       * Data prenotazione convertita in locale
       */
      const startDTLocale = getDateInTimezone(new Date(this.model.startDT!), this.configuration?.timezone ?? 'UTC');
      combineLatest([
        this.bookingService.minValidDate && this.bookingService.minValidDate > startDTLocale
          ? of(false)
          : this.bookingApiService.checkSameSlotValid(this.bookingService.selectedWarehouseData?.token!, this.model),
        this.bookingApiService.getSlots(this.bookingService.selectedWarehouseData?.token!, getSlotRequest).pipe(
          catchError((x) => {
            return of(null);
          })
        ),
      ]).subscribe(([checkSameSlotValid, x]: [boolean, GetSlotsResponseLMApi]) => {
        this.bookingService.checkSameSlotValid = checkSameSlotValid;
        if (!x) {
          let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: 'static', keyboard: false });
          ref.componentInstance.message = 'SLOT_ERROR_UNKNOWN';
          ref.componentInstance.date = null;
          ref.componentInstance.emailAddress = null;
          ref.componentInstance.phoneNumber = null;

          this.loadedSlot = true;
        } else if (x.errorMessage) {
          let ref = this.modal.open(ErrorMessageDialogComponent, { centered: true, backdrop: 'static', keyboard: false });
          ref.componentInstance.message = x.errorMessage;
          ref.componentInstance.date = x.date;
          ref.componentInstance.emailAddress = x.warehouseInternalContactEmail;
          ref.componentInstance.phoneNumber = x.warehouseInternalContactPhone;

          this.loadedSlot = true;
        } else {
          this.bookingService.selectedDate = this.bookingService.selectedDate ?? this.date;
          this.bookingService.selectedHour = this.hour;
          this.bookingService.attachmentsFileList = this.attachmentsFileList;
          this.bookingService.data = { ...this.bookingService.data, ...this.model };

          this.bookingService.getSlots = x;
          this.router.navigate(['edit-slot'], { relativeTo: this.route.parent });
        }
      });
    }
  };
}
