import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, combineLatest, map, of, switchMap } from 'rxjs';
import {
  BookingLMApiService,
  CustomerPlantModelExtLMApi,
  CustomerPlantConfigurationModelExtLMApi,
  GetPreBookingDataRequestLMApi,
  GetPreBookingDataResponseLMApi,
  PreBookingLMApiService,
} from 'src/app/api';
import { PreBookingInformation } from 'src/app/dialogs/add-prebooking-dialog/add-prebooking-dialog.component';
import { BookingService } from 'src/app/services/booking.service';
import { ByPlantService } from 'src/app/services/by-plant.service';

@Component({
  selector: 'app-book-by-plant-prebooking',
  templateUrl: 'book-by-plant-prebooking.component.html',
})
export class BookByPlantPrebookingComponent {
  loadedPrebooking: boolean | undefined;
  plantId: string | null | undefined;
  forcePrebooking: number | undefined;
  shipmentReference: string = '';
  pin: string = '';
  error: boolean = false;
  selectedWarehouseData: CustomerPlantModelExtLMApi | undefined;
  warehouseConfiguration: CustomerPlantConfigurationModelExtLMApi | undefined;
  prebookingsList: PreBookingInformation[] | undefined = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public bookingService: BookingService,
    private apiService: BookingLMApiService,
    private preBookingApiService: PreBookingLMApiService,
    private byPlantService: ByPlantService,
    private modal: NgbModal
  ) {
    combineLatest([this.route.paramMap, this.route.queryParamMap])
      .pipe(
        switchMap(([paramMap, queryParamMap]) => {
          this.plantId = paramMap.get('plantId');
          let forcePrebookingString = queryParamMap.get('forceprebooking'); // 0 no 1 si

          //GESTIONE LINGUA
          let localStorageLang = localStorage.getItem('lang');
          if (localStorageLang) this.bookingService.lang = localStorageLang;

          if (forcePrebookingString) {
            if (forcePrebookingString == '1') {
              this.forcePrebooking = 1;
            }
          }
          if (!this.plantId) {
            router.navigate(['/404']);
          }

          return this.byPlantService.verifyByPlant(this.plantId!);
        }),
        switchMap((_) => {
          //GESTIONE STEPPER
          let url = router.url.substring(1);
          this.bookingService.setStepper([
            { label: '1', url: url },
            { label: '2', url: url + '/new', desktopPhase: 0 },
            { label: '3', url: url + '/new', desktopPhase: 1 },
            { label: '4', url: url + '/new/slot' },
            { label: 'END', url: '' },
          ]);
          this.bookingService.setStepperActive(0);

          this.bookingService.fromByPlant = true;
          this.bookingService.fromPrebooking = true;
          this.prebookingsList = this.bookingService.prebookingList;

          return this.apiService.getWarehouseByPlantId(this.plantId!);
        })
      )
      .subscribe((x: CustomerPlantModelExtLMApi) => {
        this.selectedWarehouseData = x;
        if (this.selectedWarehouseData?.token) {
          this.apiService.getWarehouseConfiguration(this.selectedWarehouseData?.token).subscribe((x: CustomerPlantConfigurationModelExtLMApi) => {
            this.warehouseConfiguration = x;
            this.loadedPrebooking = true;
          });
        }
      });
  }

  /* addPrebooking = () => {
    let modalRef = this.modal.open(AddPreBookingDialogComponent, { centered: true });
    modalRef.closed.subscribe((x: PreBookingInformation) => {
      this.prebookingsList.push(x);
    });
  }; */

  loadPrebooking = () => {
    this.error = false;
    let preBookingDataRequest: GetPreBookingDataRequestLMApi = {
      shipmentReference: this.shipmentReference,
      pin: this.pin,
    };
    this.preBookingApiService
      .getPreBookingData(this.selectedWarehouseData?.token!, preBookingDataRequest)
      .pipe(
        catchError((_) => {
          return of(null);
        })
      )
      .subscribe((x: GetPreBookingDataResponseLMApi) => {
        if (x && x.jsonData) {
          if (!this.prebookingsList) this.prebookingsList = [];
          this.prebookingsList.push({ shipmentReference: this.shipmentReference, pin: this.pin, jsonData: x.jsonData } as PreBookingInformation);
          this.shipmentReference = '';
          this.pin = '';
        } else {
          this.error = true;
        }
      });
  };

  removePrebooking = (index: number) => {
    if (this.prebookingsList) this.prebookingsList.splice(index, 1);
    else this.prebookingsList = [];
    // this.prebookingsList = this.prebookingsList ? this.prebookingsList.splice(index, 1) : [];
  };

  goNextFromPrebooking = () => {
    this.bookingService.selectedWarehouseData = this.selectedWarehouseData;
    this.bookingService.warehouseConfiguration = this.warehouseConfiguration!;
    this.bookingService.checkConfiguration(this.warehouseConfiguration!);
    this.bookingService.plantCryptedId = this.plantId;
    this.bookingService.forcePrebooking = this.forcePrebooking;
    this.bookingService.prebookingList = this.prebookingsList;
    this.router.navigate(['new'], { relativeTo: this.route });
  };
}
